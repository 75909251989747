.airport-search-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  min-width: 260px;
  max-width: 400px;
}

.airport-search:hover {
  border-bottom: 1px solid rgb(132, 132, 179);
}

.airport-search:active {
  border-bottom: 1px solid rgb(132, 132, 179);
}

.airport-search-label {
  font-size: 12px;
  color: #aaa;
  margin-bottom: 4px;
  /*
  position:relative;
  bottom: -10px;
  background-color:white;
  display: inline;
  width: auto;
  */
}