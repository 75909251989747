.button-component {
  border: none;
  box-shadow: none;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  color: #fafafa;
  min-width: 80px;
  font-size: 14px;

  background: rgb(73,6,148);
  background: linear-gradient(47deg, rgba(73,6,148,1) 0%, rgba(38,8,118,1) 61%, rgba(5,6,66,1) 100%);
}

.button-component:hover {
  color: #fff;
  box-shadow: 2px 2px 8px #999;
}

.button-disabled {
  opacity: 0.3;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.button-large {
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
}