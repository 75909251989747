.flight-time-label {
  font-size: 12px;
  color: #aaa;
  margin-bottom: 4px;
}

.time-picker-component {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 38px;
  margin-bottom: 10px;
  width: 70px;
  font-size: 13px;
  text-align: center;
}