.sidebar-container {
  border-right: 1px solid #ddd;
  padding: 20px;
  width: 320px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.sidebar-logo {
  margin-top: 50px;
  width: 180px;
  margin-bottom: 56px; /* For vertically aligning with the search box on the right/main panel */
}

.sidebar-user {
  padding-top: 50px;
  padding-bottom: 50px;
}

.sidebar-footer {
  border-top: 1px solid #ddd;
  padding-top: 50px;
  font-size: 14px;
}

.sidebar-top-details {
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom:20px;
}