.flightSearchBox {
  padding: 20px 25px;
  background-color: #f9f9fb;
  border-radius: 10px;
  width: auto;
  min-width: 875px;
  display:inline-block;
  border-bottom-right-radius: 0px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hSeparator {
  border-left: 1px solid #ddd;
  margin-left: 20px;
  margin-right:20px;
  height: 40px;
  margin-top:20px;
}