.cabinclass-component {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 38px;
  margin-bottom: 10px;
  width: 130px;
  margin-right: 15px;
  font-size: 13px;
}

.cabinclass-label {
  font-size: 12px;
  color: #aaa;
  margin-bottom: 4px;
}