.image-button-component {
  border: none;
  box-shadow: none;
  text-align: center;
  display: inline-block;
  padding: 5px 6px;
  cursor: pointer;
  border-radius: 5px;
  color: #fafafa;
  width: 35px;
  height: 35px;
  font-size: 14px;
  background: rgb(73,6,148);
  background: linear-gradient(47deg, rgba(73,6,148,1) 0%, rgba(38,8,118,1) 61%, rgba(5,6,66,1) 100%);
}

.image-button-component:hover {
  color: #fff;
  box-shadow: 2px 2px 8px #999;
}

.image-button-disabled {
  opacity: 0.3;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}