*,
*::before,
*::after {
  font-family: 'Lato';
  color: #111;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

input:focus {
  outline:none;
}

select {
  appearance: none;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  border: 1px solid rgb(235, 233, 253);
  border-radius: 5px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  padding: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

a {
  text-decoration: none;
  color: #111;
}

a:hover {
  border-bottom: 1px solid rgb(161, 245, 65);
}

/* Used in the popover rows  */
.gray-hover:hover {
  background-color: #fafafa;
}

/* Enable to debug grid and adjustments
div, td {
  outline:1px red dotted;
}
td {
  outline:1px blue dotted;
}
*/