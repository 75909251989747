.search-button-component {
  border: none;
  box-shadow: none;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  padding-top: 8px;
  cursor: pointer;
  color: #eee;
  width: 200px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  position: relative;
  top:-10px;
}

.search-button-component:hover {
  color: white;
}

.search-button-disabled {
  opacity: 0.3;
  -webkit-user-select: none;
  user-select: none;
}