.radio-button-component {
  border-radius: 30px;
  width: 20px;
  height: 20px;
  background-color: #200971;
  color: white;
  box-shadow: 0px 0px 6px #999;
  cursor: pointer;
}

.radio-button-component:hover {
  box-shadow: 0px 0px 6px #999;
}

.radio-selection-icon {
  position: relative;
  left: 4px;
}

.radio-selection-icon > path {
  color: white;
}

.radio-button-off {
  background-color: white;
  box-shadow: 0px 0px 2px #999;  
}