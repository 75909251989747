.remove-option-row {
  background-color: #ddd;
  border-radius: 15px;
  text-align: center;
  width: 20px;
  height: 20px;
  font-size: 13px;
  padding-top:1.5px;
  color: white;
  position: absolute;
  cursor: pointer;
  right:-10px;
  top:-10px;
}

.remove-option-row:hover {
  background-color: #C13B61;
}

.climate-options-table tr td {
  padding-bottom: 5px;
  padding-top: 5px;
  vertical-align: middle;
}