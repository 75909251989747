.flight-date-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: 140px;
  margin-right: 15px;
  caret-color: transparent;
  cursor: pointer;
}

.flight-date:hover {
  border-bottom: 1px solid rgb(132, 132, 179);
}

.flight-date:active {
  border-bottom: 1px solid rgb(132, 132, 179);
}

.flight-date-label {
  font-size: 12px;
  color: #aaa;
  margin-bottom: 4px;
}