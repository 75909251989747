.price-editor-input {
  border-radius: 5px;
  background-color:rgb(255, 243, 197);
  display: inline-block;
  cursor: pointer;
  margin-top:3px;
  padding: 3px 5px;
}

.price-editor-input:hover {
  background-color:rgb(255, 249, 224);
}