.flight-result-list {
  margin-top: 20px;
  width: 100%;
  max-width: 875px;
}

.flight-result-list table {
  width: 100%;
}

.flight-result-list table th {
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  padding: 10px;
  padding-left: 0px;
  user-select: none;
}

.flight-result-list table td {
  text-align: left;
  padding-bottom: 6px;
  padding-top: 6px;
  font-size: 14px;
  cursor: pointer;
}

.flight-bundle-row-separator td {
  border-bottom: 1px solid #ddd;
}

.flight-result-outbound > td {
  padding-top:15px !important;
}

.sort-table-header-column {
padding: 10px;
}

.sort-table-header-column:hover {
  color: #390685;
}